import { classNames, getSymb } from "@helpers/helper";
import { checkImageUrl } from "@helpers/helperAds";
import { useWhiteLabelLan } from "@hooks/useLayout";
import Image from "next/image";
import { useState } from "react";

type RecentSearchesCarType = {
  data: MainCardType;
  handleRedirectUrl: (
    urlObj: string | UrlLabelType | undefined,
    type: string
  ) => void;
};
export const CarRS: React.FC<RecentSearchesCarType> = ({
  data,
  handleRedirectUrl,
}) => {
  const { getLangLabel } = useWhiteLabelLan();
  const [errorImage, setErrorImage] = useState(false);
  console.log("carsDads", data);
  return (
    <div
      className="tw-relative tw-flex tw-bg-clip-border tw-mx-2 tw-rounded-xl tw-bg-white tw-text-gray-700 tw-shadow-[0px_0px_10px_0px_rgba(0,0,0,0.3)] tw-drop-shadow-xl lg:tw-shadow-md tw-h-[134px] tw-w-[300px] tw-max-w-[300px] tw-flex-row tw-mb-1 tw-cursor-pointer "
      onClick={() => {
        handleRedirectUrl(data?.url, "Car");
      }}
    >
      <div
        className={classNames(
          " tw-relative tw-w-2/5  tw-m-0   tw-text-gray-700  tw-bg-white  tw-rounded-r-none  tw-bg-clip-border  tw-rounded-xl  tw-shrink-0 flex items-center",
          errorImage ? "" : "tw-mt-[10%]"
        )}
      >
        <Image
          src={
            Array.isArray(data.image)
              ? checkImageUrl(data.image[0])
              : checkImageUrl(data.image)
          }
          alt="image"
          width={650}
          height={400}
          className=" tw-rounded-l-lg tw-mt-6"
          layout={errorImage ? "fill" : "responsive"}
          onError={(e) => {
            setErrorImage(true);
            e.currentTarget.src = "/images/hotel-card/no-img-found.png";
          }}
        />
      </div>
      <div className=" tw-w-3/5 tw-p-2 tw-pr-4">
        <div className="tw-min-h-16">
          <h6 className="tw-text-lg tw-font-medium tw-text-gray-500 tw-w-full tw-relative [&>div]:hover:tw-visible">
            <div className=" title-ad-card tw-hover:text-clip tw-w-[85%] tw-block tw-text-[16px]/[15px] tw-mb-2  tw-font-sans  tw-antialiased  tw-font-bold  tw-leading-relaxed  tw-tracking-normal  tw-text-[#3f3f3f] tw-truncate tw-line-clamp-[1]">
              {data.title}
            </div>
            <div
              role="tooltip"
              className="tw-absolute tw-invisible tw-left-0 -tw-top-0 tw-z-10 tw-inline-block tw-px-3 tw-py-2 tw-text-sm tw-font-medium tw-text-gray-900 tw-bg-white tw-border tw-border-gray-200 tw-rounded-lg tw-shadow-sm"
            >
              {data.title}
            </div>
          </h6>
          <h4 className="tw-mb-0 tw-text-sm/[12px] tw-font-medium tw-text-[#757575]">
            {data.subtitle}
          </h4>
          <h4 className=" subtitle-ad-card tw-mb-0 tw-font-sans tw-text-xs/[18px] tw-text-[#929292]">
            {data.subtitle2}
          </h4>
        </div>
        <div className="tw-grid tw-grid-cols-2  tw-gap-1">
          <div className="tw-mt-[20%]">
            {" "}
            <Image
              src={data.brandImage ?? ""}
              alt="image"
              width={40}
              height={20}
              className=""
            />
          </div>
          <div className="tw-mt-[10%] tw-text-[#929292]   tw-font-medium tw-text-sm tw-flex tw-flex-row">
            <div className="tw-flex w-100 tw-justify-between tw-items-start tw-align-sub">
              <div className="tw-text-[11px]/[18px] tw-text-[#6d6d6d] tw-font-normal ">
                {getLangLabel("FROM")}:
              </div>
              <div className="tw-text-base tw-text-[#6d6d6d] tw-font-semibold tw-text-[16px] tw-leading-snug tw-flex tw-flex-col">
                <span className=" tw-text-[22px]">
                  {`${getSymb()}${Number(data.priceInfo).toFixed(0)}`}
                </span>
                <span className=" tw-text-[10px]/[4px] tw-text-[#6d6d6d] tw-font-normal tw-text-end tw-mt-1">
                  {getLangLabel("PER DAY")}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
