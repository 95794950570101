import { CardCenter2 } from "@components/Ads/CardCenter2";
import { Guarantee } from "@components/Ads/Guarantee";
import { Layout } from "@components/Layout";
import { Search } from "@components/Search";
import { useState } from "react";
// import dynamic from "next/dynamic";
import { BannerV3 } from "@components/AdsBanner/BannerV3";
import { CardCenterV2 } from "@components/Ads/CardCenterV2";
import { RecentSearchesv2 } from "@components/Ads/RecentSearchesv2";
import { SectionCardsAds } from "@components/Ads/SectionCardsAds";

type HomeV2Props = {
  adsBanner?: MainCardType[];
  search?: any;
  isLogged: boolean;
  section?: string;
  sectionsAds?: any[];
};
// const RecentSearchesv2 = dynamic(
//   () => import("@components/Ads/RecentSearchesv2")
// );

export const HomeV2: React.FC<HomeV2Props> = ({
  adsBanner,
  search,
  isLogged,
  section,
  sectionsAds,
}) => {
  const [refresh, setRefresh] = useState(false);
  const resortSection = sectionsAds?.find((f) => f.name == "resorts");
  const resortSpace = resortSection?.space;
  const promotionSection = sectionsAds?.find((f) => f.name == "promotions");
  const promotionSpace = promotionSection?.space;
  const destinationSection = sectionsAds?.find((f) => f.name == "destinations");
  const destinationSpace = destinationSection?.space;
  const hotelSection = sectionsAds?.find((f) => f.name == "hotels");
  const hotelSpace = hotelSection?.space;
  const hotelTitle = hotelSection?.title;
  const hotelSubtitle = hotelSection?.subtitle;
  return (
    <Layout>
      <section className=" tw-flex  tw-flex-col-reverse lg:tw-flex-col">
        <BannerV3 data={adsBanner} />
        <div className=" tw-pt-4 tw-pb-16 lg:tw-p-0 ">
          {!search && isLogged && (
            <Search refresh={refresh} setRefresh={setRefresh} />
          )}
          {isLogged && search}
        </div>
      </section>
      <div className="tw-pt-16 md:tw-pb-16 main-suggestions-section">
        <section className=" tw-w-full tw-flex tw-flex-row tw-justify-center tw-items-center">
          <div className=" tw-w-full tw-px-4 md:tw-px-0 tw-max-w-[85%] ">
            <RecentSearchesv2 />
            <SectionCardsAds section={section} space={resortSpace} />
            <CardCenterV2 section={section} space={promotionSpace} />
            <SectionCardsAds
              section={section}
              space={hotelSpace}
              title={hotelTitle}
              subtitle={hotelSubtitle}
            />
          </div>
        </section>{" "}
        <section className=" tw-w-full tw-flex tw-flex-row tw-justify-center tw-items-center">
          <div className="tw-w-full tw-px-4 md:tw-px-0 ">
            <CardCenter2 section={section} space={destinationSpace} />
          </div>
        </section>
        <Guarantee />
      </div>
    </Layout>
  );
};
