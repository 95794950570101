import Slider from "react-slick";

import Image from "next/image";
import { classNames } from "@helpers/helper";
import { ResortType2 } from "./ResortType2";
import { useAdsToShow } from "@hooks/useAds";
import { textValidation, urlBodyV2Button } from "@lib/ads";
import { HotelType2 } from "./HotelType2";
import { useWhiteLabelLan } from "@hooks/useLayout";
import { HotelType1 } from "./HotelType1";

type BannerProps = {
  section?: string;
  space?: string;
  title?: string;
  subtitle?: string;
};

export const SectionCardsAds: React.FC<BannerProps> = ({
  section = "hotels",
  space = "space3",
  title = "RESORT DESTINATIONS JUST FOR YOU",
  subtitle = "Destinations for long stays in exclusive properties with amazing prices",
}) => {
  const { getLangLabel } = useWhiteLabelLan();
  const { data, isLoading } = useAdsToShow(section, space);
  function PrevArrow(props: any) {
    const { onClick } = props;
    return (
      <button
        onClick={onClick}
        className={classNames(
          " prev-arrow-hpd",
          " tw-w-12 lg:-tw-w-[65px] tw-h-auto",
          " !tw-z-40 tw-border-none tw-shadow-4xl  tw-bg-white tw-absolute tw-top-1/2 tw-rounded-full tw-align-middle tw-left-0 lg:tw-left-[-60px] tw-transform tw--translate-y-1/2 tw-inline-flex tw-items-center tw-justify-center",
          " tw-ml-[-1rem] tw-top-[7.2rem]",
          " md:tw-ml-[-1rem] md:tw-mt-[-5rem] md:tw-top-[50%]",
          " lg:tw-ml-[-1rem]"
        )}
      >
        <Image
          src="/images/hotel-detail/gallery-popup-icons/arrow.svg"
          width={50}
          height={50}
          alt="arrow"
          className="tw-transform tw-self-center !tw-z-40 hover:tw-scale-110 tw-transition-transform tw-duration-300 tw-ease-in-out"
        />
      </button>
    );
  }

  function NextArrow(props: any) {
    const { onClick } = props;
    return (
      <button
        onClick={onClick}
        className={classNames(
          " next-arrow-hpd",
          " tw-w-12 lg:-tw-w-[65px] tw-h-auto",
          " !tw-z-40 tw-border-none tw-shadow-4xl tw-rotate-180  tw-bg-white tw-absolute tw-top-1/2 tw-rounded-full tw-align-middle tw-right-3 lg:tw-right-[-60px] tw-transform tw--translate-y-1/2 tw-inline-flex tw-items-center tw-justify-center",
          " tw-relative tw-ml-[97%] tw-mt-[-30rem]",
          " md:tw-absolute md:tw-left-[0%] md:tw-flex md:tw-mt-[-5rem]",
          " lg:tw-left-[auto] lg:tw-flex"
        )}
      >
        <Image
          src="/images/hotel-detail/gallery-popup-icons/arrow.svg"
          width={50}
          height={50}
          alt="arrow"
          className="tw-transform tw-self-center !tw-z-40 hover:tw-scale-110 tw-transition-transform tw-duration-300 tw-ease-in-out"
        />
      </button>
    );
  }
  const settings = {
    infinite: true,
    // centerMode: true,
    centerPadding: "0px",
    slidesToShow: 1,
    speed: 500,
    addaptiveHeight: true,
    variableWidth: true,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          addaptiveHeight: true,
          variableWidth: true,
        },
      },
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 2,
          addaptiveHeight: true,
          variableWidth: true,
          centerMode: false,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          addaptiveHeight: true,
          variableWidth: true,
          centerMode: false,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          addaptiveHeight: true,
          variableWidth: true,
          infinite: true,
          centerMode: false,
        },
      },
    ],
  };

  if (isLoading) return <></>;
  if (!data || (Array.isArray(data) && data.length == 0)) return <></>;

  return (
    <section className="tw-pt-[3rem] tw-w-full tw-flex tw-flex-col tw-self-center section-hpd">
      <header className="  tw-w-full tw-tracking-[1.55px]">
        <h1 className="homev2-title tw-text-black tw-uppercase  tw-text-xl lg:tw-text-[31px] tw-font-bold  lg:tw-text-3xl">
          {getLangLabel(title)}
        </h1>
        <h2 className="homev2-subtitle tw-text-gray-600 tw-font-medium tw-text-xl tw-hidden lg:tw-inline-flex">
          {getLangLabel(subtitle)}
        </h2>
      </header>
      <div className="tw-flex tw-flex-row tw-justify-center tw-w-full tw-mt-4">
        <div className="slider-container tw-relative  tw-w-full tw-text-black tw-mb-4 [&>div>div]:tw-overflow-x-clip [&>div>div]:tw-overflow-y-visible ">
          <Slider {...settings}>
            {data.map((dataAds, index) => {
              const imageSelect =
                typeof dataAds.image === "string" ? dataAds.image : "";
              const title = dataAds.title || "";
              const subTitle = dataAds.subtitle || "";
              const stars = Number(dataAds.stars);
              const price = dataAds.price;
              const discount = Number(dataAds.discount || "5");
              const checkInDate = dataAds.checkInDate;
              const checkOutDate = dataAds.checkOutDate;
              const score = dataAds.score;

              let buttonUrl;
              if (dataAds?.buttonText) {
                buttonUrl = dataAds?.url || "";
              } else {
                buttonUrl = urlBodyV2Button(dataAds?.url)?.url || "";
                buttonUrl = buttonUrl || "";
              }
              if (dataAds?.petitionTypeStr == "hotel") {
                const offerTitle = dataAds.offerTitle || "";
                if (offerTitle) {
                  return (
                    <HotelType1
                      key={`center-item-hotel-ads-res-${index}`}
                      image={imageSelect}
                      title={textValidation(title) || ""}
                      subTitle={textValidation(subTitle) || ""}
                      offerTitle={textValidation(offerTitle) || ""}
                      stars={stars || 3}
                      discount={discount}
                      score={score || 3}
                      checkinDate={checkInDate || ""}
                      checkOutDate={checkOutDate || ""}
                      buttonUrl={buttonUrl}
                    />
                  );
                }
                return (
                  <HotelType2
                    key={`center-item-hotel-ads-res-${index}`}
                    image={imageSelect}
                    title={textValidation(title) || ""}
                    subTitle={textValidation(subTitle) || ""}
                    stars={stars || 3}
                    discount={discount}
                    score={score || 3}
                    checkinDate={checkInDate || ""}
                    checkOutDate={checkOutDate || ""}
                    buttonUrl={buttonUrl}
                  />
                );
              } else if (dataAds?.petitionTypeStr == "resort") {
                const offerTitle = dataAds.offerTitle || "";
                const checkin = dataAds.checkInDate || "";
                const checkOut = dataAds.checkOutDate || "";
                if (offerTitle && !checkin && !checkOut) {
                  return (
                    <HotelType1
                      key={`center-item-resort-ads-res-${index}`}
                      image={imageSelect}
                      title={textValidation(title) || ""}
                      subTitle={textValidation(subTitle) || ""}
                      offerTitle={textValidation(offerTitle) || ""}
                      stars={stars || 3}
                      discount={discount}
                      score={score || 3}
                      checkinDate={checkInDate || ""}
                      checkOutDate={checkOutDate || ""}
                      buttonUrl={buttonUrl}
                    />
                  );
                }
                return (
                  <ResortType2
                    key={`center-item-ads-res-${index}`}
                    image={imageSelect}
                    title={textValidation(title) || ""}
                    subTitle={textValidation(subTitle) || ""}
                    stars={stars || 3}
                    price={price}
                    score={score || 3}
                    checkinDate={checkInDate || ""}
                    checkOutDate={checkOutDate || ""}
                    buttonUrl={buttonUrl}
                  />
                );
              }
              return null;
            })}
          </Slider>
        </div>
      </div>
    </section>
  );
};
