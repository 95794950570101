import Image from "next/image";
import { checkUrlType } from "@lib/ads";
import { useState } from "react";
import { classNames } from "@helpers/helper";

type BannerProps = {
  image: string;
  title: string;
  redirectUrl?: string | UrlLabelType | AdsBannerUrl;
};

export const CardType1: React.FC<BannerProps> = ({
  image,
  title,
  redirectUrl,
}) => {
  const [isHover, setisHover] = useState(false);

  return (
    <div
      onMouseEnter={() => setisHover(true)}
      onMouseLeave={() => setisHover(false)}
      className={classNames(
        "tw-relative tw-mx-1 tw-w-[287px] tw-h-[378px] tw-rounded-2xl tw-cursor-pointer tw-z-[1] lg:hover:tw-scale-[1.12]  tw-transform tw-transition-transform  hover:tw-z-[10] tw-shadow-4xl",
        isHover
          ? "lg:tw-scale-[1.12] tw-z-[10] tw-shadow-[0px_20px_16px_4px_#000] tw-shadow-gray-700"
          : "tw-scale-[1] tw-z-[1]"
      )}
      onClick={() => checkUrlType(redirectUrl)}
    >
      <Image
        src={image}
        alt="image"
        width={287}
        height={378}
        className=" tw-rounded-2xl "
      />
      <span
        className={classNames(
          "tw-text-white tw-text-xl tw-font-bold tw-absolute tw-rounded-2xl tw-bottom-0 tw-left-0 tw-uppercase tw-z-[3] tw-w-full tw-h-[20%]  tw-inline-flex tw-justify-start tw-px-8 tw-align-middle tw-items-center",
          isHover ? "tw-bg-opacity-10 tw-bg-black" : "tw-bg-opacity-100"
        )}
      >
        {title}
      </span>
      <div
        className={classNames(
          " tw-invisible lg:tw-visible tw-absolute tw-bg-black  tw-rounded-2xl tw-top-0 tw-left-0 tw-bg-opacity-35 hover:tw-bg-opacity-0 tw-z-[2] tw-w-full tw-h-[378px]",
          isHover ? "tw-bg-opacity-0" : "tw-bg-opacity-35"
        )}
      ></div>
    </div>
  );
};
