import { classNames } from "@helpers/helper";

type Props = {
  stars: number;
  classColor?: string;
};

export default function Stars({ stars, classColor }: Props) {
  const starsArray = Array.from(Array(5).keys());

  return (
    <div className="tw-flex tw-items-center">
      {starsArray.map((star) => {
        if (star <= stars - 1) {
          return (
            <svg
              key={star}
              className={classNames(
                "tw-w-3 tw-h-3 tw-text-yellow-300 tw-ms-1",
                classColor || ""
              )}
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 22 20"
            >
              <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
            </svg>
          );
        }

        return (
          <svg
            key={star}
            className="tw-w-3 tw-h-3 ms-1 tw-text-gray-300 dark:tw-text-gray-500"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 22 20"
          >
            <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
          </svg>
        );
      })}
    </div>
  );
}
