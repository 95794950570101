import Image from "next/image";
import { useWhiteLabelLan } from "@hooks/useLayout";

type BannerProps = {};

export const PriceGuaranteeAd: React.FC<BannerProps> = ({}) => {
  const { getLangLabel } = useWhiteLabelLan();

  return (
    <section className="homev2-guarantee tw-w-full tw-flex tw-flex-col md:tw-flex-row tw-bg-inspira-purple-700 tw-text-white tw-min-h-[500px] tw-mt-20">
      <div className="tw-w-full md:tw-w-1/2 lg:tw-w-3/5 tw-flex tw-flex-col tw-h-[500px] tw-justify-center tw-items-start tw-px-10 lg:tw-px-24 tw-relative">
        <h1 className=" tw-uppercase tw-text-2xl lg:tw-text-7xl tw-font-normal tw-leading-10 tw-tracking-widest tw-z-20">
          {getLangLabel("booking with 110% guarantee")}
        </h1>
        <h2 className="tw-font-normal tw-text-lg lg:tw-text-3xl lg:tw-text-[28px] tw-leading-10 tw-pt-6 tw-tracking-widest tw-z-20">
          {getLangLabel(
            "Find a price lower that ours, we will give you back 110% of the difference"
          )}
        </h2>
        <button
          onClick={() => {
            window.open("https://promos.network/priceguarantee", "_blank");
          }}
          className=" tw-tracking-widest tw-bg-transparent tw-font-bold tw-inline-flex tw-items-center tw-text-white tw-text-xl tw-mt-4 tw-rounded-md tw-align-middle tw-justify-center tw-h-10 tw-z-20"
        >
          {getLangLabel("More Info")}{" "}
          <Image
            src="/images/hotel-detail/arrowW.svg"
            alt="arrow"
            width={50}
            height={20}
            className="tw-transform tw-self-center tw-rotate-180"
          />
        </button>
        <div className="tw-w-[60%] tw-h-[200px] tw-absolute tw-bottom-0 tw-right-0 md:tw-right-[-15px] tw-z-10">
          <Image
            src="/images/Ads/stripes.png"
            alt="100% Satisfaction Guarantee"
            className=""
            width={500}
            height={500}
            layout="fill"
          />
        </div>
      </div>
      <div className="tw-w-full md:tw-w-1/2 lg:tw-w-2/5 tw-h-[500px] tw-flex tw-justify-center tw-items-center tw-relative tw-z-20">
        <Image
          src="/images/Ads/AdGuarantee.png"
          alt="100% Satisfaction Guarantee"
          className="tw-w-full tw-h-full"
          width={500}
          height={500}
          layout="fill"
        />
      </div>
    </section>
  );
};
