import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { CardType1 } from "./CardType1";
import Image from "next/image";
import { classNames } from "@helpers/helper";
import { useAdsToShow } from "@hooks/useAds";
import { textValidation, urlBodyV2Button, validateImageUrl } from "@lib/ads";
import { useWhiteLabelLan } from "@hooks/useLayout";

type BannerProps = {
  section?: string;
  space?: string;
};
export const CardCenter2: React.FC<BannerProps> = ({
  section = "hotels",
  space = "space2",
}) => {
  const { getLangLabel } = useWhiteLabelLan();
  const { data, isLoading } = useAdsToShow(section, space);

  function PrevArrow(props: any) {
    const { onClick } = props;
    return (
      <button
        onClick={onClick}
        className={classNames(
          " tw-w-12 lg:-tw-w-[65px] tw-h-auto",
          " !tw-z-40 tw-border-none tw-shadow-4xl  tw-bg-white tw-absolute tw-top-1/2 tw-rounded-full tw-align-middle tw-pl-0 lg:tw-left-[-30px] tw-transform tw--translate-y-1/2 tw-items-center tw-justify-center tw-hidden lg:tw-inline-flex ",
          " lg:tw-top-[25%] lg:tw-left-[-10%]",
          " xl:tw-top-[45%] lg:tw-left-[-5%]"
        )}
      >
        <Image
          src="/images/hotel-detail/gallery-popup-icons/arrow.svg"
          width={60}
          height={60}
          alt="arrow"
          className="tw-transform tw-self-center !tw-z-40 hover:tw-scale-110 tw-transition-transform tw-duration-300 tw-ease-in-out"
        />
      </button>
    );
  }

  function NextArrow(props: any) {
    const { onClick } = props;
    return (
      <button
        onClick={onClick}
        className={classNames(
          " tw-w-12 lg:-tw-w-[65px] tw-h-auto",
          " !tw-z-40 tw-border-none lg:tw-shadow-4xl  tw-rotate-180 tw-bg-white tw-absolute tw-top-1/2 tw-rounded-full tw-align-middle tw-right-[-50px] lg:tw-right-[10%] tw-transform tw--translate-y-1/2 tw-items-center tw-justify-center  tw-hidden lg:tw-inline-flex ",
          " lg:tw-top-[25%] lg:tw-left-[93%]",
          " xl:tw-top-[45%] lg:tw-left-[93%]"
        )}
      >
        <Image
          src="/images/hotel-detail/gallery-popup-icons/arrow.svg"
          width={60}
          height={60}
          alt="arrow"
          className="tw-transform tw-self-center !tw-z-40 hover:tw-scale-110 tw-transition-transform tw-duration-300 tw-ease-in-out"
        />
      </button>
    );
  }
  const settings = {
    infinite: true,
    slidesToShow: 5,
    speed: 500,
    addaptiveHeight: true,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 2500,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
          addaptiveHeight: true,
          variableWidth: true,
        },
      },
      {
        breakpoint: 2000,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
          addaptiveHeight: true,
          variableWidth: true,
        },
      },
      {
        breakpoint: 1800,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
          addaptiveHeight: true,
          variableWidth: true,
        },
      },
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          addaptiveHeight: true,
          variableWidth: true,
        },
      },
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          addaptiveHeight: true,
          variableWidth: true,
        },
      },

      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          addaptiveHeight: true,
          variableWidth: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          addaptiveHeight: true,
          variableWidth: false,
        },
      },
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 1,
          addaptiveHeight: true,
          variableWidth: true,
          infinite: true,
          centerMode: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          addaptiveHeight: true,
          variableWidth: true,
          infinite: true,
          centerMode: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          addaptiveHeight: true,
          variableWidth: true,
          infinite: true,
          centerMode: false,
        },
      },
    ],
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
  };

  if (isLoading) return <></>;
  if (!data || (Array.isArray(data) && data.length == 0)) return <></>;
  return (
    <section className="tw-flex tw-flex-col tw-align-middle tw-mt-10 section-next-trip">
      <header className="tw-w-full tw-tracking-[1.55px] tw-self-center tw-max-w-[85%]">
        <h1 className="homev2-title tw-text-black tw-uppercase tw-pl-4 lg:tw-pl-0  tw-text-xl lg:tw-text-[31px] tw-font-bold  lg:tw-text-3xl">
          {getLangLabel("GET INSPIRATION FOR YOUR NEXT TRIP")}
        </h1>
        <h2 className="homev2-subtitle tw-pl-4 lg:tw-pl-0 tw-text-gray-600 tw-font-medium tw-text-xl tw-hidden lg:tw-inline-flex">
          {getLangLabel(
            "A list of inspirational destinations that will give you an excuse to travel"
          )}
        </h2>
      </header>
      <div className="tw-flex tw-flex-row tw-pl-4 lg:tw-px-0 lg:tw-justify-end tw-w-full tw-mt-4 tw-justify-start md:tw-pl-10 div-next-trip">
        <div className="slider-container tw-relative tw-w-full md:tw-w-[90%]  tw-text-black tw-mb-4 [&>div>div]:!tw-p-0 [&>div>div>div]:tw-ml-[2rem] [&>div>div]:tw-overflow-x-clip [&>div>div]:tw-overflow-y-visible  tw-mt-6">
          <Slider {...settings}>
            {data?.map((dataAds, index) => {
              const imageSelect =
                typeof dataAds.image === "string" ? dataAds.image : "";
              const titleSelect = dataAds.title;

              let buttonUrl;
              if (dataAds?.buttonText && dataAds?.url) {
                buttonUrl = dataAds?.url || "";
              } else {
                buttonUrl = urlBodyV2Button(dataAds?.url)?.url || "";
              }
              return (
                <CardType1
                  key={`center-item-d-${index}`}
                  image={validateImageUrl(imageSelect, "vertical/")}
                  redirectUrl={buttonUrl}
                  title={textValidation(titleSelect) || ""}
                />
              );
            })}
          </Slider>
        </div>
      </div>
    </section>
  );
};
