export const RecentSearchesSkeleton: React.FC = () => {
  return (
    <div>
      <div className=" lg:tw-mb-[2rem]">
        <div className="tw-p-4  lg:tw-pl-[10%]">
          <div className="tw-row-start-1 tw-row-span-1 tw-p-4 tw-h-2">
            <div className="tw-grid tw-grid-cols-3 tw-gap-4">
              <div className="tw-h-6 tw-bg-slate-300 tw-rounded tw-col-span-2 lg:tw-col-span-1"></div>
            </div>
          </div>
          <div className="tw-row-start-1 tw-row-span-1 tw-p-4 tw-h-2">
            <div className="tw-grid tw-grid-cols-3 tw-gap-4">
              <div className="tw-h-4 tw-bg-slate-300 tw-rounded tw-col-span-2 lg:tw-col-span-1"></div>
            </div>
          </div>
        </div>

        <div className="tw-grid tw-grid-rows-1 tw-grid-flow-col tw-gap-4 tw-pl-1">
          <div className="tw-relative tw-row-start-1 tw-row-span-1 tw-t">
            <div className="tw-absolute tw-m-auto tw-left-0 tw-right-0 tw-top-0 tw-bottom-0 tw-rounded-full tw-bg-slate-300 tw-h-10 tw-w-10"></div>
          </div>
          <div className="tw-row-start-1 tw-row-span-1 ...">
            <div className="tw-border tw-border-slate-300 tw-shadow tw-rounded-md tw-p-4 tw-max-w-sm tw-w-full tw-mx-auto">
              <div className="tw-animate-pulse tw-flex tw-space-x-4">
                <div className="tw-rounded-full tw-bg-slate-300 tw-h-10 tw-w-10"></div>
                <div className="tw-flex-1 tw-space-y-6 tw-py-1">
                  <div className="tw-h-2 tw-bg-slate-300 tw-rounded"></div>
                  <div className="tw-space-y-3">
                    <div className="tw-grid tw-grid-cols-3 tw-gap-4">
                      <div className="tw-h-2 tw-bg-slate-300 tw-rounded tw-col-span-2"></div>
                      <div className="tw-h-2 tw-bg-slate-300 tw-rounded tw-col-span-1"></div>
                    </div>
                    <div className="tw-h-2 tw-bg-slate-300 tw-rounded"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="tw-row-start-1 tw-row-end-1 tw-hidden lg:tw-block ...">
            <div className="tw-border tw-border-slate-300 tw-shadow tw-rounded-md tw-p-4 tw-max-w-sm tw-w-full tw-mx-auto">
              <div className="tw-animate-pulse tw-flex tw-space-x-4">
                <div className="tw-rounded-full tw-bg-slate-300 tw-h-10 tw-w-10"></div>
                <div className="tw-flex-1 tw-space-y-6 tw-py-1">
                  <div className="tw-h-2 tw-bg-slate-300 tw-rounded"></div>
                  <div className="tw-space-y-3">
                    <div className="tw-grid tw-grid-cols-3 tw-gap-4">
                      <div className="tw-h-2 tw-bg-slate-300 tw-rounded tw-col-span-2"></div>
                      <div className="tw-h-2 tw-bg-slate-300 tw-rounded tw-col-span-1"></div>
                    </div>
                    <div className="tw-h-2 tw-bg-slate-300 tw-rounded"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="tw-row-start-1 tw-row-end-1 tw-hidden lg:tw-block ...">
            <div className="tw-border tw-border-slate-300 tw-shadow tw-rounded-md tw-p-4 tw-max-w-sm tw-w-full tw-mx-auto">
              <div className="tw-animate-pulse tw-flex tw-space-x-4">
                <div className="tw-rounded-full tw-bg-slate-300 tw-h-10 tw-w-10"></div>
                <div className="tw-flex-1 tw-space-y-6 tw-py-1">
                  <div className="tw-h-2 tw-bg-slate-300 tw-rounded"></div>
                  <div className="tw-space-y-3">
                    <div className="tw-grid tw-grid-cols-3 tw-gap-4">
                      <div className="tw-h-2 tw-bg-slate-300 tw-rounded tw-col-span-2"></div>
                      <div className="tw-h-2 tw-bg-slate-300 tw-rounded tw-col-span-1"></div>
                    </div>
                    <div className="tw-h-2 tw-bg-slate-300 tw-rounded"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="tw-row-start-1 tw-row-end-1 tw-hidden lg:tw-block ...">
            <div className="tw-border tw-border-slate-300 tw-shadow tw-rounded-md tw-p-4 tw-max-w-sm tw-w-full tw-mx-auto">
              <div className="tw-animate-pulse tw-flex tw-space-x-4">
                <div className="tw-rounded-full tw-bg-slate-300 tw-h-10 tw-w-10"></div>
                <div className="tw-flex-1 tw-space-y-6 tw-py-1">
                  <div className="tw-h-2 tw-bg-slate-300 tw-rounded"></div>
                  <div className="tw-space-y-3">
                    <div className="tw-grid tw-grid-cols-3 tw-gap-4">
                      <div className="tw-h-2 tw-bg-slate-300 tw-rounded tw-col-span-2"></div>
                      <div className="tw-h-2 tw-bg-slate-300 tw-rounded tw-col-span-1"></div>
                    </div>
                    <div className="tw-h-2 tw-bg-slate-300 tw-rounded"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="tw-relative tw-row-start-1 tw-row-span-1 tw-t tw-pr-1">
            <div className="tw-absolute tw-m-auto tw-left-0 tw-right-0 tw-top-0 tw-bottom-0 tw-rounded-full tw-bg-slate-300 tw-h-10 tw-w-10"></div>
          </div>
        </div>
      </div>
    </div>
  );
};
