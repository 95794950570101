import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import { useRef } from "react";
import { CarRS } from "./Car";
import { useCallback, useEffect, useRef, useState } from "react";
import { getRecentSearchByPage } from "@lib/api/home/getRecentSearchByPage";
import {
  sanitizCarData,
  sanitizeFlightData,
  sanitizeHotelData,
} from "@helpers/helperRecentSearches";
import {
  MATCH_URL_SPECIAL_PARAMS_WITH_ANGLE,
  filterRepeatItems,
  orderObjectByDate,
  setAdsCoordinates,
} from "@helpers/helperAds";
import { useMain } from "@contexts/mainContext";
import { useAuth } from "@contexts/AuthContext";
import { useWindowSize } from "@hooks/useWindowSize";
import { useLayoutStore } from "@store/layoutStore";
import { shallow } from "zustand/shallow";
import { HotelRS } from "./hotel";
import Image from "next/image";
import { classNames } from "@helpers/helper";
import { RecentSearchesSkeleton } from "./Skeleton";
import { checkCookies, getCookie } from "cookies-next";
import { useDinamicUrl } from "@hooks/useAds";
import Router from "next/router";
import { useHotelSearchDetailStore } from "@store/hotelSearchDetailStore";
import { FlightRS } from "./Flight";
import { useWhiteLabelLan } from "@hooks/useLayout";

type BannerProps = {};

export const RecentSearchesv2: React.FC<BannerProps> = ({}) => {
  const { getLangLabel } = useWhiteLabelLan();
  const { dateFormat } = useMain();
  const { isLogged, currentUser } = useAuth();
  const size = useWindowSize();
  let { getUserInfoByKey } = useDinamicUrl();
  const [sanitizedData, setSanitizedData] = useState<MainCardType[]>([]);
  const [isTriggeredApi, setIsTriggeredApi] = useState(true);
  const [showSkeleton, setShowSkeleton] = useState(true);
  let sliderRef: any = useRef<any>(null);
  const { resetHotelSearchByRoomOcupancyHotelCards } =
    useHotelSearchDetailStore();
  const { showRecentSearchesMobile } = useLayoutStore(
    (state) => ({
      showRecentSearchesMobile: state.externalConfig?.showRecentSearchesMobile,
    }),
    shallow
  );

  function PrevArrow(props: any) {
    const { onClick } = props;
    return (
      <button
        onClick={onClick}
        className={classNames(
          "!tw-z-40 tw-border-none tw-shadow-4xl  tw-bg-white tw-absolute tw-top-1/2 tw-rounded-full tw-align-middle tw-left-[-30px] lg:tw-left-[-60px] tw-transform tw--translate-y-1/2 lg:tw-inline-flex tw-items-center tw-justify-center tw-hidden "
        )}
      >
        <Image
          src="/images/hotel-detail/gallery-popup-icons/arrow.svg"
          width={size.width >= 992 ? 50 : 30}
          height={size.width >= 992 ? 50 : 30}
          alt="arrow"
          className="tw-transform tw-self-center !tw-z-40 hover:tw-scale-110 tw-transition-transform tw-duration-300 tw-ease-in-out"
        />
      </button>
    );
  }

  function NextArrow(props: any) {
    const { onClick } = props;
    return (
      <button
        onClick={onClick}
        className={classNames(
          " !tw-z-40 tw-border-none tw-shadow-4xl  tw-rotate-180  tw-bg-white tw-absolute tw-top-1/2 tw-rounded-full tw-align-middle tw-right-[-30px] lg:tw-right-[-60px] tw-transform tw--translate-y-1/2 lg:tw-inline-flex tw-items-center tw-justify-center tw-hidden"
        )}
      >
        <Image
          src="/images/hotel-detail/gallery-popup-icons/arrow.svg"
          width={size.width >= 992 ? 50 : 30}
          height={size.width >= 992 ? 50 : 30}
          alt="arrow"
          className="tw-transform tw-self-center !tw-z-40 hover:tw-scale-110 tw-transition-transform tw-duration-300 tw-ease-in-out"
        />
      </button>
    );
  }

  const settings = {
    // className: "center",
    // centerMode: true,
    infinite: false,
    variableWidth: true,
    adaptiveHeight: true,
    // centerPadding: "10px",
    slidesToShow: 4,
    slidesToScroll: 1,
    speed: 500,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    responsive: [
      {
        breakpoint: 1224,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          addaptiveHeight: true,
          variableWidth: true,
        },
      },

      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          addaptiveHeight: true,
          variableWidth: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          addaptiveHeight: true,
          variableWidth: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          addaptiveHeight: true,
          variableWidth: false,
        },
      },
    ],
  };

  const getDataApi = useCallback(async () => {
    let temp = sanitizedData;
    const response1 = await getRecentSearchByPage("ALL");
    setIsTriggeredApi(false);
    const dataHotels = response1?.hotels;
    const dataCars = response1?.cars;
    const dataFlights = response1?.flights;

    if (Array.isArray(dataHotels) && dataHotels.length > 0) {
      const htlCard = sanitizeHotelData(dataHotels ?? []);
      // orderObjectByDate(carCard, "desc");
      let tmp = temp.concat(htlCard);
      temp = tmp;
    }

    if (Array.isArray(dataCars) && dataCars.length > 0) {
      const carCard = sanitizCarData(dataCars ?? []);
      // orderObjectByDate(carCard, "desc");
      let tmp = temp.concat(filterRepeatItems(carCard, "car"));
      temp = tmp;
    }
    if (Array.isArray(dataFlights) && dataFlights.length > 0) {
      const flightCard = sanitizeFlightData(
        dataFlights ?? [],
        dateFormat || "YYYY/MM/DD"
      );
      const filteredArr = filterRepeatItems(flightCard, "flight");
      let tmp = temp.concat(filteredArr);
      temp = tmp;
    }

    orderObjectByDate(temp, "desc");
    console.log("temp-- lengh", temp, temp.length);
    if (temp.length == 0) {
      setShowSkeleton(false);
    }
    setSanitizedData(temp);

    // setIsloading(false);
    setIsTriggeredApi(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRedirectUrl = (
    urlObj: string | UrlLabelType | undefined,
    type: string
  ) => {
    let lanCode = String(getCookie("lan_code"));
    let code = checkCookies("lan_code") ? lanCode.split("-")[0] : "en";
    var urlRedirect: string | undefined = "";
    var defaultUrlRedirect: string | undefined = "";
    let findUrl = false;
    if (urlObj) {
      if (typeof urlObj === "object") {
        for (let [key, value] of Object.entries(urlObj)) {
          if (key === code) {
            findUrl = true;
            urlRedirect = value;
          }
          if (key === "en") {
            defaultUrlRedirect = value;
          }
        }
      } else {
        urlRedirect = urlObj;
      }
    }

    if (!findUrl) {
      urlRedirect = defaultUrlRedirect;
    }

    setAdsCoordinates(urlRedirect);

    if (urlRedirect !== "") {
      //section URL whith special params
      const isFilledUrl = urlRedirect.match(
        MATCH_URL_SPECIAL_PARAMS_WITH_ANGLE
      );
      if (isLogged && currentUser) {
        if (Array.isArray(isFilledUrl) && isFilledUrl.length > 0) {
          let tmpurlRedirect = getUserInfoByKey(
            urlRedirect,
            isFilledUrl,
            currentUser
          );
          if (tmpurlRedirect) {
            urlRedirect = tmpurlRedirect;
          }
        }
      }

      let firstLetters = urlRedirect.substring(0, 3);
      if (firstLetters.includes("htt") || firstLetters.includes("ww")) {
        if (type == "hotel") {
          resetHotelSearchByRoomOcupancyHotelCards();
        }
        window.location.replace(`${urlRedirect}`);
      } else {
        if (Router.pathname.indexOf("cars") > 0) {
          if (Router.pathname === "/cars/search-details") {
            Router.replace({
              pathname: `/cars/search-details`,
              query: `${urlRedirect}`,
            }).then(() => Router.reload());
          } else {
            Router.push({
              pathname: `/cars/search-details`,
              query: `${urlRedirect}`,
            });
          }
        } else if (Router.pathname.indexOf("flight") > 0) {
          if (Router.pathname === "/flight/search-details") {
            Router.replace({
              pathname: `/flight/search-details`,
              query: `${urlRedirect}`,
            }).then(() => Router.reload());
          } else {
            Router.push({
              pathname: `/flight/search-details`,
              query: `${urlRedirect}`,
            });
          }
        } else if (Router.pathname === "/search-detail") {
          Router.replace({
            pathname: `/search-detail`,
            query: `${urlRedirect}`,
          }).then(() => Router.reload());
        } else {
          if (type == "hotel") {
            resetHotelSearchByRoomOcupancyHotelCards();
            setTimeout(() => {
              Router.push({
                pathname: `${window.location.origin}/search-detail`,
                query: `${urlRedirect}`,
              });
            }, 100);
          } else if (type == "car") {
            Router.push({
              pathname: `${window.location.origin}/cars/search-details`,
              query: `${urlRedirect}`,
            });
          } else if (type == "flight") {
            Router.push({
              pathname: `${window.location.origin}/flight/search-details`,
              query: `${urlRedirect}`,
            });
          }
        }
      }
    }
  };

  useEffect(() => {
    if (isLogged) {
      if (isTriggeredApi && sanitizedData.length == 0) {
        let runOnMobile = size.width >= 992; // true is not mobile
        console.log("sliderRef", sliderRef);
        if (size.width == 0) {
          let isIos = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
          if (isIos) {
            if (typeof window !== "undefined") {
              let iosSize = window.innerWidth;
              runOnMobile = iosSize >= 992;
            }
          } else {
            if (typeof window !== "undefined") {
              let iosSize = window.innerWidth;
              runOnMobile = iosSize >= 992;
            }
          }
        }

        if (showRecentSearchesMobile) {
          runOnMobile = true;
        }
        runOnMobile;
        // if (runOnMobile) {
        getDataApi();
        // }
      }
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogged, isTriggeredApi]);

  if (!isLogged || (!showSkeleton && sanitizedData.length == 0)) {
    return <></>;
  }

  if (
    isLogged &&
    Array.isArray(sanitizedData) &&
    sanitizedData.length == 0 &&
    showSkeleton
  ) {
    return <RecentSearchesSkeleton />;
  }

  return (
    <div className="tw-pt-[3rem]   tw-w-full tw-flex tw-flex-col tw-self-center">
      <div className="tw-w-full">
        <h1 className="homev2-title tw-text-black tw-tracking-[1.55px] tw-uppercase tw-text-xl lg:tw-text-[31px] tw-font-bold  lg:tw-text-3xl">
          {getLangLabel("YOUR RECENT SEARCHES")}
        </h1>
        <h2 className="homev2-subtitle tw-text-gray-600 tw-font-medium tw-text-xl tw-hidden lg:tw-block tw-tracking-[1.55px]">
          {getLangLabel("Keep track and get that perfect deal!")}
        </h2>
      </div>
      <div className="slider-container tw-text-black tw-pt-4 lg:tw-pt-10 tw-w-full tw-flex tw-flex-col [&>div>div>div]:tw-inline-flex [&>div>div]:tw-overflow-x-clip [&>div>div]:tw-overflow-y-visible">
        <Slider
          ref={(slider: any) => {
            sliderRef = slider;
          }}
          {...settings}
        >
          {Array.isArray(sanitizedData) &&
            sanitizedData?.map((data: MainCardType, index: number) => {
              if (data?.type == "car") {
                return (
                  <CarRS
                    key={`rsc-${index}`}
                    data={data}
                    handleRedirectUrl={handleRedirectUrl}
                  ></CarRS>
                );
              }
              if (data?.type == "hotel") {
                return (
                  <HotelRS
                    key={`rsh-${index}`}
                    data={data}
                    handleRedirectUrl={handleRedirectUrl}
                  ></HotelRS>
                );
              }
              if (data?.type == "flight") {
                return (
                  <FlightRS
                    key={`rsf-${index}`}
                    data={data}
                    handleRedirectUrl={handleRedirectUrl}
                  ></FlightRS>
                );
              }
            })}
        </Slider>
      </div>
    </div>
  );
};
