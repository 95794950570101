import Slider, { LazyLoadTypes } from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Image from "next/image";
import { classNames } from "@helpers/helper";
import { useState } from "react";
import {
  checkUrlType,
  handleButtonTxt,
  textValidation,
  urlBodyV2Button,
  validateImageUrl,
} from "@lib/ads";

type BannerProps = { data?: MainCardType[] };

const lazyLoad: LazyLoadTypes = "anticipated";
export const BannerV3: React.FC<BannerProps> = ({ data }) => {
  const [activeSlide, setActiveSlide] = useState(0);
  function SampleNextArrow(props: any) {
    const { onClick } = props;
    return (
      <button
        onClick={onClick}
        className={classNames(
          " !tw-z-40 tw-border-none tw-shadow-4xl tw-w-10 lg:tw-w-[60px]   tw-rotate-180 tw-opacity-50  tw-bg-white tw-absolute tw-top-1/2 tw-rounded-full tw-align-middle tw-right-[1rem] lg:tw-right-[2rem] tw-transform tw--translate-y-1/2 tw-inline-flex tw-items-center tw-justify-center"
        )}
      >
        <Image
          src="/images/hotel-detail/gallery-popup-icons/arrow.svg"
          width={60}
          height={60}
          alt="arrow"
          className="tw-transform tw-self-center !tw-z-40 hover:tw-scale-110 tw-transition-transform tw-duration-300 tw-ease-in-out"
        />
      </button>
    );
  }

  function SamplePrevArrow(props: any) {
    const { onClick } = props;
    return (
      <button
        onClick={onClick}
        className={classNames(
          " !tw-z-40 tw-border-none tw-shadow-4xl tw-w-10 lg:tw-w-[60px] tw-opacity-50 tw-bg-white tw-absolute tw-top-1/2 tw-rounded-full tw-align-middle tw-left-[1rem] lg:tw-left-[2rem] tw-transform tw--translate-y-1/2 tw-inline-flex tw-items-center tw-justify-center"
        )}
      >
        <Image
          src="/images/hotel-detail/gallery-popup-icons/arrow.svg"
          width={60}
          height={60}
          alt="arrow"
          className="tw-transform tw-self-center !tw-z-40 hover:tw-scale-110 tw-transition-transform tw-duration-300 tw-ease-in-out"
        />
      </button>
    );
  }
  const showArrows = () => {
    return data && data?.length > 1;
  };
  const settings = {
    dots: showArrows(),
    lazyLoad: lazyLoad,
    infinite: true,
    fade: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: !showArrows() ? <></> : <SampleNextArrow />,
    prevArrow: !showArrows() ? <></> : <SamplePrevArrow />,
    customPaging: (i: number) => (
      <a className=" tw-invisible md:tw-visible tw-bottom-[7rem] lg:tw-bottom-[10rem] tw-absolute tw-w-[20px]">
        <Image
          src={
            activeSlide == i
              ? `/images/general/activo.png`
              : "/images/general/inactivo.png"
          }
          width={50}
          height={50}
          alt="dot"
          className=" "
        />
      </a>
    ),
    afterChange: (current: number) => setActiveSlide(current),
  };
  return (
    <div className="slider-container ">
      <Slider {...settings}>
        {data?.map((item, index) => {
          let buttonText = "";
          let buttonUrl: any;
          if (item?.buttonText) {
            buttonText = handleButtonTxt(item?.buttonText) || "";
            buttonUrl = item?.url || "";
          } else {
            buttonText = urlBodyV2Button(item?.url)?.text || "";
            buttonUrl = urlBodyV2Button(item?.url)?.url || "";
            buttonUrl = buttonUrl || "";
          }
          let title: any;
          let description: any;
          try {
            if (item?.title) {
              title = item?.title;
            } else if (
              Array.isArray(item?.textInfo) &&
              item?.textInfo?.length > 0
            ) {
              title = item?.textInfo?.at(0)?.text || "";
            }
            if (item?.description) {
              description = item?.description;
            } else if (
              Array.isArray(item?.textInfo) &&
              item?.textInfo?.length > 1
            ) {
              description = item?.textInfo?.at(1)?.text || "";
            }
          } catch (error: any) {
            console.error("Error in BannerV3", error);
            title = "";
            description = "";
          }
          return (
            <div
              key={`banner-item-${index}`}
              className="lg:tw-h-[80vh] tw-w-full tw-h-[300px] md:tw-h-[500px] tw-relative"
            >
              <Image
                src={validateImageUrl(item.image)}
                alt="banner"
                className="tw-w-full "
                width={1200}
                height={600}
                layout="fill"
                objectPosition={"center"}
                objectFit="cover"
              />
              <div className=" tw-flex  tw-align-middle tw-flex-col  tw-w-full tw-absolute tw-top-0 tw-left-0 tw-h-full ">
                <div className=" tw-self-center tw-flex tw-flex-col tw-container tw-h-[300px] md:tw-h-[500px] lg:tw-h-[600px] 2xl:tw-h-[782px] tw-align-middle  tw-justify-center ">
                  <div className=" tw-px-16 sm:tw-px-12 md:tw-px-16 lg:tw-px-20 xl:tw-px-28 tw-text-center md:tw-text-left tw-w-full">
                    <div className="lg:tw-pr-[25%]">
                      <h1 className="tw-text-white tw-uppercase tw-text-xl tw-font-[700] md:tw-text-3xl lg:tw-text-6xl lg:tw-text-[53px] tw-transform tw-tracking-[5.3px] main-text-banner">
                        {textValidation(title)}
                      </h1>
                      <p className="tw-text-white tw-text-lg tw-font-light tw-text-center md:tw-text-left md:tw-text-xl lg:tw-text-3xl lg:tw-text-[27px] tw-mt-4 lg:tw-font-normal tw-tracking-[1.35px] subtitle-text-banner">
                        {textValidation(description)}
                      </p>
                    </div>
                    <button
                      onClick={() => {
                        if (buttonUrl) checkUrlType(buttonUrl);
                      }}
                      className={classNames(
                        "tw-tracking-[1.38px]",
                        "tw-m-0 md:tw-mt-7 tw-py-2 tw-bg-white tw-px-7 tw-text-black tw-rounded-full tw-uppercase tw-font-semibold tw-text-lg",
                        !buttonText ? "tw-invisible" : "",
                        "banner-btn2"
                      )}
                    >
                      {buttonText}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </Slider>
    </div>
  );
};

// export default BannerV3;
